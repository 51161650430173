export const sourceflowEnums = {
  forms: {
    register: {
      en: "b4954117-631c-4db6-9c2e-deb36d048d30",
    },
    contactUs: {
      en: "71258204-15cd-49ba-99df-e3d4db19ba0d",
    },
    eventSignup: {
      en: "1b9897b7-f951-4e3f-9caa-5a8a1d4bbe70",
    },
    ir35: {
      en: "0c293198-9647-48a1-962f-5cb6d6bfb206",
    },
  },
  categories: {
    sectors: {
      id: "84ddafd9-a498-4832-91ab-40d7a04f98ab",
      technology: "140cafef-9f0b-452d-b0a1-61f26c73c6c9",
      insurance: "a34382f5-c378-4741-8259-329720186bd3",
      banking: "dd9c00c4-043f-4739-822d-ad54c5df76ab",
    },
    specialisms: {
      id: "0425a8d5-c834-4c2c-b43c-00b7a26079d7",
    },
    type: {
      id: "25feb2e7-fa3e-4526-acd8-b8cc05ba9c0d",
      contract: "a60b95f5-d1b3-45bc-8b13-18e732181477",
      permanent: "1cca1ad0-61a3-4a78-a267-9f4df976a355",
      both: "3a4391d7-e2b7-43d6-bb0e-1bd4ecdea640",
    },
    working_environment: {
      id: "33ac11b2-5562-427f-88a6-8fcb3cc5472f",
      any: "1dfce9ac-2cb0-470d-9bf2-c00e427b12ca",
      hybrid: "b3d2865e-3677-460c-9979-003845333ac8",
      onsite: "9053f900-f84a-4852-a1d5-b27a1e2e8dd8",
      remote: "09bb1480-ea8e-4864-ba3f-fdd5f9a09ee6",
    },
  },
  config: {
    baseUrl: "https://www.gravitasgroup.com.hk",
    siteName: "Gravitas Recruitment Group Hong Kong",
    socialLogo:
      "https://media.licdn.com/dms/image/C4D0BAQHN9687UaD-WQ/company-logo_200_200/0/1630579047955/gravitas_recruitment_group_uk_logo?e=2147483647&v=beta&t=y-gvCgEAM8WC-AzexuKI8iNgiJFtdab_W-IPJabbacA",
    recruitmentTypes: {
      contract: "contract",
      permanent: "permanent",
      retained: "retained",
      array: ["contract", "permanent", "retained"],
    },
  },
};

export default sourceflowEnums;
