import data from "../../.sourceflow/menu_main.json";
import BaseCollection from "@sourceflow-uk/sourceflow-sdk/base_collection";

export const getMainMenu = () => {
  const collection = new BaseCollection(data, "en").getItems();

  return collection
    .filter((i) => i.parent.id === null)
    .map((i) => {
      const children = collection
        .filter((c) => c.parent.id === i.id)
        .map((c) => {
          const children = collection.filter((cc) => cc.parent.id === c.id);

          return children ? { ...c, children } : c;
        });

      return children ? { ...i, children } : i;
    });
};
