import { Col, Nav, Navbar, Offcanvas, Row, Stack } from "react-bootstrap";
import classes from "./styles.module.scss";
import { useLocale } from "@/hooks/useLocale";
import { RegisterAside } from "@/ui/RegisterAside";
import { SocialLinks } from "@/ui/SocialLinks";
import { getMainMenu } from "@/functions/getMainMenu";
import { useLink } from "@/hooks/useLink";
export const MainMenu = ({ show, onHide }) => {
  const locale = useLocale();
  const link = useLink();
  const mainMenu = getMainMenu();

  return (
    <Navbar.Offcanvas show={show} onHide={onHide} className={classes.menu}>
      <Offcanvas.Body>
        <RegisterAside className="flex-row text-white align-items-center" />
        <Stack className="flex-column flex-md-row flex-grow-1">
          <Nav className="d-flex flex-row">
            <Row>
              <Col xs={6}>
                <Nav.Item className="mb-2">
                  <Nav.Link href={link("home")}>Home</Nav.Link>
                </Nav.Item>
              </Col>
              {mainMenu.map((i, k) => (
                <Col key={k} xs={6}>
                  <Nav.Item className="mb-2">
                    <Nav.Link href={i[`url_${locale}`]} dangerouslySetInnerHTML={{ __html: i[`label_${locale}`] }} />
                    {i.children && (
                      <div>
                        {i.children.map((i, k) => (
                          <Nav.Item key={k}>
                            <Nav.Link
                              href={i[`url_${locale}`]}
                              dangerouslySetInnerHTML={{ __html: i[`label_${locale}`] }}
                            />
                          </Nav.Item>
                        ))}
                      </div>
                    )}
                  </Nav.Item>
                </Col>
              ))}
            </Row>
          </Nav>
          <div className="ms-4 pt-2 w-100">
            <SocialLinks />
          </div>
        </Stack>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  );
};
