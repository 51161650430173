import "@/scss/styles.scss";
import { AppTemplate } from "@/ui/AppTemplate";
import { useLocale } from "@/hooks/useLocale";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../.sourceflow/metadata.json";
import sourceflowEnums from "@/sourceflow.enums";

const DEFAULT_META_PROPS = {
  metaObject,
  siteName: sourceflowEnums.config.siteName,
  defaultTitle: sourceflowEnums.config.siteName,
  images: [{ url: sourceflowEnums.config.socialLogo }],
  addTracker: true,
  cookieExpiryTime: 3000,
  robots: "index, follow",
};

export default function App({ Component, pageProps }) {
  const locale = useLocale();
  const metaProps =
    "meta" in pageProps ? Object.assign({}, DEFAULT_META_PROPS, pageProps?.meta[locale]) : DEFAULT_META_PROPS;

  return (
    <>
      <SourceFlowHead
        {...metaProps}
        title={`${metaProps.title} | ${metaProps.siteName}`}
        languageAlternates={[{ href: metaProps.canonical, hrefLang: locale }]} // explicitly set alternate
      />
      <AppTemplate router={pageProps.router} footer={pageProps.footer}>
        <Component {...pageProps} />
      </AppTemplate>
    </>
  );
}
