import SourceFlowForm from "@sourceflow-uk/sourceflowform";
import SourceFlowApplicationForm from "@sourceflow-uk/sourceflowapplicationform";
import { Helmet } from "react-helmet";
import classes from "./styles.module.scss";
import clsx from "classnames";

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export const Form = ({ className, formId = null, jobId = null, onSubmitDone = () => {} }) => {
  if (formId === null && jobId == null) {
    return null;
  }

  return (
    <div className={clsx(className, classes.form)}>
      <Helmet>
        <link id="fa" rel="stylesheet" href="https://use.fontawesome.com/0348a6c5fa.css" />
      </Helmet>
      {jobId ? (
        <SourceFlowApplicationForm jobId={jobId} onSubmitDone={onSubmitDone} />
      ) : (
        <SourceFlowForm formId={formId} onSubmitDone={onSubmitDone} />
      )}
    </div>
  );
};
