import { Container, Navbar } from "react-bootstrap";
import LogoDarkEn from "@/assets/LogoDarkEN.svg";
import { HeaderMenu } from "./components/HeaderMenu";
// import { LocaleSwitcher } from "./components/LocaleSwitcher";
import { HeaderButton } from "./components/HeaderButton";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { useLink } from "@/hooks/useLink";
import { useState } from "react";
import { MainMenu } from "@/ui/AppHeader/components/MainMenu";

export const AppHeader = ({ className, router }) => {
  const link = useLink();
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={classes.header__bg} />
      <Navbar expand={false} variant="dark" className={clsx(className, classes.header, "py-0")}>
        <Container className="d-flex flex-nowrap px-0 ps-0 pe-md-3" fluid>
          <Navbar.Toggle className="d-block d-lg-none flex-shrink-1" onClick={() => setShow(!show)}>
            <span />
            <span />
          </Navbar.Toggle>
          <Navbar.Brand className={clsx("me-auto px-3 py-0 px-md-4", { show })}>
            <a href={link("home")}>
              <LogoDarkEn />
            </a>
          </Navbar.Brand>
          <HeaderMenu className="d-none d-lg-flex" />
          <HeaderButton className="ms-lg-3 d-none d-sm-block" />
          {/*<LocaleSwitcher className="ms-3" router={router} />*/}
        </Container>
        <MainMenu show={show} onHide={() => setShow(false)} />
      </Navbar>
    </>
  );
};
