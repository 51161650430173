import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import { Form } from "@/ui/Form";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { useLink } from "@/hooks/useLink";
import { DynamicHtml } from "@/ui/DynamicHtml";
import sourceflowEnums from "@/sourceflow.enums";

export const FooterForm = ({ className }) => {
  const locale = useLocale();
  const link = useLink();

  const onSubmitDone = () => {
    if (typeof window !== "undefined") {
      window.location.href = link("form.complete");
    }
  };

  return (
    <div className={clsx(className, classes.form)}>
      <DynamicText path={`footer.form.title.${locale}`} tag="div" className="h5 mb-2" />
      <DynamicHtml path={`footer.form.text.${locale}`} className="mb-4 small" />
      <Form className="mb-5" formId={sourceflowEnums.forms.contactUs[locale]} onSubmitDone={onSubmitDone} />
    </div>
  );
};
