import { Nav } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { getFooterMenu } from "@/functions/getFooterMenu";

export const FooterMenu = ({ className }) => {
  const locale = useLocale();
  const footerMenu = getFooterMenu();

  return (
    <div className={clsx(className, classes.menu, "mt-4 mt-md-0")}>
      <DynamicText path={`footer.menu.title.${locale}`} tag="div" className="h5 mb-2 mb-md-4" />
      <Nav className="flex-column">
        {footerMenu.map((i, k) => (
          <Nav.Item key={k}>
            <Nav.Link href={i[`url_${locale}`]} target={i[`url_${locale}`].startsWith("http") ? "_blank" : "_self"}>
              {i[`label_${locale}`]}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
};
