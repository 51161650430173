import data from "../../.sourceflow/menu_footer.json";
import BaseCollection from "@sourceflow-uk/sourceflow-sdk/base_collection";

export const getFooterMenu = () => {
  const collection = new BaseCollection(data, "en").getItems();

  return collection
    .filter((i) => i.parent.id === null)
    .map((i) => {
      const children = collection.filter((c) => c.parent.id === i.id);

      return children ? { ...i, children } : i;
    });
};
