import { Stack } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { socials } from "@/data/socials";
import clsx from "classnames";

export const SocialLinks = ({ className }) => {
  const locale = useLocale();

  return (
    <Stack className={clsx(className, "flex-grow-0")} gap={3}>
      <DynamicText path={`footer.socials.label.${locale}`} className="fw-semibold" />
      <Stack className="flex-row" gap={3}>
        {socials.map(({ url, icon }, k) => (
          <a href={url} key={k} className="d-flex align-items-center" rel="nofollow">
            {icon}
          </a>
        ))}
      </Stack>
    </Stack>
  );
};
