import LinkedIn from "@/assets/LinkedIn.svg";
import Facebook from "@/assets/Facebook.svg";
import Twitter from "@/assets/twitter_logo.svg";
import Instagram from "@/assets/Instagram.svg";

export const socials = [
  {
    url: "https://www.linkedin.com/company/gravitas-recruitment-group-global",
    icon: <LinkedIn />,
  },
  {
    url: "https://www.facebook.com/gravitasrecruitmentgroup",
    icon: <Facebook />,
  },
  // {
  // url: "https://twitter.com/GravitasJobs",
  // icon: <Twitter />,
  // },
  {
    url: "https://www.instagram.com/gravitasrecruitmentgroup",
    icon: <Instagram />,
  },
];
