import { AppHeader } from "@/ui/AppHeader";
import { AppFooter } from "@/ui/AppFooter";
import { AppSidebar } from "@/ui/AppSidebar";

export const AppTemplate = ({ children, router, footer }) => {
  return (
    <div className="d-flex flex-grow-1">
      <div className="d-flex flex-column flex-grow-1">
        <AppHeader router={router} />
        <main className="flex-grow-1 pt-2 pt-md-4">{children}</main>
        <AppFooter {...footer} />
      </div>
      <AppSidebar />
    </div>
  );
};
