import { router } from "@/router";

export const getLink = ({ key, path, locale, url_slug = null, params = "" }) => {
  if (path) {
    const route =
      path === "/" ? router["home"] : Object.values(router).find((r) => Object.values(r).some((v) => v.includes(path)));

    return route ? route[locale] : path;
  }

  if (url_slug) {
    if (Array.isArray(url_slug)) {
      return `${router[key][locale].replace("[url_slugs]", url_slug.join("/"))}${params}`;
    }

    return `${router[key][locale].replace("[url_slug]", url_slug)}${params}`;
  }

  return `${router[key][locale]}${params}`;
};
