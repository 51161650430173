import { Col, Container, Row, Stack } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { SocialLinks } from "@/ui/SocialLinks";
import { FooterMenu } from "./components/FooterMenu";
import { FooterOfficesMenu } from "./components/FooterOfficesMenu";
import { FooterForm } from "./components/FooterForm";
import LogoLightEN from "@/assets/LogoLightEN.svg";
import SourceFlow from "@/assets/SourceFlow.svg";
import { useLocale } from "@/hooks/useLocale";
import { useLink } from "@/hooks/useLink";
import classes from "./styles.module.scss";
import clsx from "classnames";
import Address from "@/assets/Address.svg";
import Email from "@/assets/Email.svg";
import { RegisterAside } from "@/ui/RegisterAside";

export const AppFooter = ({ className, showForm }) => {
  const locale = useLocale();
  const link = useLink();

  return (
    <div className={clsx(className, classes.footer)}>
      <Container fluid className="h-lg-100">
        <Row className="h-lg-100">
          <Col
            xs={12}
            lg={4}
            className="bg-primary text-white py-4 h-lg-100 d-flex flex-column justify-content-between"
          >
            <a href={link("home")} className={clsx(classes.footer__logo, "mb-5 mx-auto")}>
              <LogoLightEN />
            </a>
            <SocialLinks className="flex-row mx-auto mt-5 align-items-center" />
          </Col>
          <Col xs={12} lg={8} className="py-4 px-4 text-center text-md-start" style={{ maxWidth: 920 }}>
            {showForm && <FooterForm className="mx-auto ms-md-0 me-md-3" />}
            <Stack className="flex-column flex-md-row justify-content-between" gap={{ xs: 4, md: 0, xl: 5 }}>
              <FooterOfficesMenu className="me-0 me-md-3" />
              <FooterMenu />
              <div>
                <RegisterAside className={classes.footer__register} />
              </div>
            </Stack>
            <Stack className="flex-column flex-md-row justify-content-between mt-5">
              <div className="mb-4 mb-md-0">
                <dl className={clsx(classes.footer__detail, "gap-2 mb-2")}>
                  <dt>
                    <Email width={21} height={21} />
                    <span className="visually-hidden">Email</span>
                  </dt>
                  <dd>
                    <DynamicText path={`footer.email.${locale}`} />
                  </dd>
                </dl>
                <dl className={clsx(classes.footer__detail, "gap-2")}>
                  <dt>
                    <Address width={21} height={21} />
                    <span className="visually-hidden">Address</span>
                  </dt>
                  <dd>
                    <DynamicText path={`footer.address.${locale}`} />
                  </dd>
                </dl>
              </div>
              <div className="d-flex flex-column align-items-end justify-content-end gap-2">
                <DynamicText path={`footer.copyright.${locale}`} className={classes.footer__copyright} />
                <a href="https://www.sourceflow.co.uk" target="_blank" className={classes.footer__siteby}>
                  <DynamicText path={`footer.siteby.${locale}`} className="me-2" />
                  <SourceFlow />
                </a>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AppFooter.defaultProps = {
  showForm: true,
};
