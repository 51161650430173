import { Nav } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { getMainMenu } from "@/functions/getMainMenu";
import { useState } from "react";

export const HeaderMenu = ({ className }) => {
  const locale = useLocale();
  const mainMenu = getMainMenu();
  const [active, setActive] = useState({ id: null });
  const [childActive, setChildActive] = useState({ id: null });

  return (
    <Nav className={clsx(className, classes.menu)}>
      {mainMenu.map((i) => {
        return (
          <Nav.Item
            key={i.id}
            className={classes.menu__navItem}
            onMouseOver={() => setActive(i)}
            onMouseOut={() => setActive({ id: null })}
          >
            <Nav.Link className={clsx(classes.menu__navLink, "px-2 py-3")} href={i[`url_${locale}`]}>
              {i[`label_${locale}`]}
            </Nav.Link>
            {i.children.length > 0 && (
              <Nav
                key={i.id}
                className={clsx(classes.menu__dropdown, "py-2", {
                  "d-none": active.id !== i.id,
                  "d-block": active.id === i.id,
                })}
              >
                {i.children.map((c) => (
                  <Nav.Item
                    key={c.id}
                    onMouseOver={() => setChildActive(c)}
                    onMouseOut={() => setChildActive({ id: null })}
                  >
                    <Nav.Link className={clsx(classes.menu__dropdownLink, "px-3 py-1")} href={c[`url_${locale}`]}>
                      {c[`label_${locale}`]}
                    </Nav.Link>
                    {c.children.length > 0 && (
                      <Nav
                        key={c.id}
                        className={clsx(classes.menu__dropdownChild, "py-2", {
                          "d-none": childActive.id !== c.id,
                          "d-block": childActive.id === c.id,
                        })}
                      >
                        {c.children.map((cc) => (
                          <Nav.Item key={cc.id}>
                            <Nav.Link
                              className={clsx(classes.menu__dropdownChildLink, "px-3 py-1")}
                              href={cc[`url_${locale}`]}
                            >
                              {cc[`label_${locale}`]}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    )}
                  </Nav.Item>
                ))}
              </Nav>
            )}
          </Nav.Item>
        );
      })}
    </Nav>
  );
};
