/* eslint-disable */
import React from "react";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import { getGlobal } from "@/functions/getGlobal";
import { placeholders } from "@/data/placeholders";

/**
 *
 * @param path
 * @param tag
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const DynamicText = ({ path, tag, dangerouslySetInnerHTML, ...props }) => {
  let textProps = {
    type: "text",
    global: getGlobal(),
    path: path.toLowerCase(),
    children: <span dangerouslySetInnerHTML={dangerouslySetInnerHTML ?? { __html: placeholders[path] }} />,
  };

  if (tag) {
    return React.createElement(tag, props, <SourceFlowText {...textProps} />);
  }

  return <SourceFlowText {...textProps} {...props} />;
};

DynamicText.defaultProps = {
  tag: null,
};
