import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import { Nav } from "react-bootstrap";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { footerOfficesMenu } from "@/data/footerOfficesMenu";

export const FooterOfficesMenu = ({ className }) => {
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.offices, "mt-4 mt-md-0")}>
      <DynamicText path={`footer.offices.title.${locale}`} tag="div" className="h5 mb-2 mb-md-4" />
      <Nav className="flex-column">
        {footerOfficesMenu[locale].map(({ label, url, target }, k) => (
          <Nav.Item key={k}>
            <Nav.Link href={url} target={target}>
              {label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
};
