export const footerOfficesMenu = {
  en: [
    {
      label: "United Kingdom",
      url: "https://www.gravitasgroup.co.uk/",
      target: "_blank",
    },
    {
      label: "Germany",
      url: "https://www.gravitasgroup.de/",
      target: "_blank",
    },
    {
      label: "The Netherlands",
      url: "https://www.gravitasgroup.nl/",
      target: "_blank",
    },
    {
      label: "Hong Kong & Mainland China",
      url: "https://www.gravitasgroup.com.hk/",
      target: "_blank",
    },
    {
      label: "Singapore",
      url: "https://www.gravitasgroup.com.sg/",
      target: "_blank",
    },
    {
      label: "Work with us globally",
      url: "https://gravitasgroup.com/",
      target: "_blank",
    },
  ],
};
