import clsx from "classnames";
import { DynamicText } from "@/ui/DynamicText";
import { Button, Stack } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { useLink } from "@/hooks/useLink";
import classes from "./styles.module.scss";

export const RegisterAside = ({ className }) => {
  const locale = useLocale();
  const link = useLink();

  return (
    <Stack
      as={"aside"}
      className={clsx(classes.register, className, "justify-content-between p-4 mt-md-4 mb-4")}
      gap={4}
    >
      <DynamicText path={`aside.register.title.${locale}`} className="h6 mb-0" />
      <Button href={link("form.register")} variant="secondary">
        <DynamicText path={`aside.register.button.${locale}`} />
      </Button>
    </Stack>
  );
};
